import React from 'react'
import './Contact.css';
import { MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w7mgg4s', 'template_thj209s', form.current, 'rx6y0AkK9raQsRsbB')
      .then((result) => {
          // console.log(result.text);
          alert("Email sent successfully!");
      }, (error) => {
          // console.log(error.text);
          alert("FAILED!"+error);
      });

      e.target.reset();
  };


  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>awanishyadavhoon@gmail.com</h5>
            <a href="mailto:awanishyadavhoon@gmail.com" target='_blank'>Send a Message</a>
          </article>
          <article className='contact__option'>
            <FaWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>+91 8423678383</h5>
            <a href="https://wa.me/919818993183" target='_blank'>Send a Message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="text" name='email' placeholder='Your Email' required/>
          <input type="text" name='subject' placeholder='Enter Subject' required/>
          <textarea type="text" name='message'  rows="7" placeholder='Your Message' required/>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact;