import React from 'react'
import './Header.css';
import CTA from './CTA';
import ME from '../../assets/me.png';
import HeaderSocial from './HeaderSocial';


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Awanish Yadav</h1>
        <h5 className='text-light'>Data Analyst, Product Analyst, Dashboard Designer, Alteryx Designer</h5>
        <CTA/>
        <HeaderSocial/>

        <div className='me'>
          <img src={ME} alt="me" />
        </div>

        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  )
}

export default Header;